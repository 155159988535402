import {
  WIX_EVENTS_TICKET_DEFINITION_FQDN,
  PlaceWithTicketInfo,
  getEventTicketLimitPerOrder,
  saleEnded,
  isDonationTicketDefinition,
  InvoiceSelectedItems,
  getTicketDefinitionCurrency,
} from '@wix/wix-events-commons-statics'
import {createSelector} from 'reselect'
import {SeatingPlan, Type, Element, Place} from '@wix/ambassador-seating-v1-seating-plan/types'
import {TFunction} from '@wix/yoshi-flow-editor'
import {DropdownOptionProps} from 'wix-ui-tpa'
import {SeatsInfo, SelectedTickets, State, TicketsToPlaces} from '../types'
import {getSelectedTicketQuantity, getSelectedTicketsQuantity, sortTicketDefinitionIds} from './selected-tickets'
import {getTicketById, getTickets} from './tickets'
import {getTicketPriceText} from './order-success'

export const getTicketDefinitionByExternalId = (tickets: wix.events.ticketing.TicketDefinition[], externalId: string) =>
  tickets.find(ticketDef => ticketDef.id === externalId.replace(WIX_EVENTS_TICKET_DEFINITION_FQDN, ''))

export const getPlaces = (state: State) => state.seating.places

export const getSelectedPrice = (state: State) => state.seating.selectedPrice

export const getSelectedZone = (state: State) => state.seating.selectedZone

export const getFilteredPlaces = createSelector(
  getPlaces,
  getSelectedPrice,
  getSelectedZone,
  getTickets,
  (places, price, zone, tickets) => {
    places = places.filter(place => {
      const ticket = getTicketById(tickets, place.ticket?.id)
      const isAvailable = place.capacity
      return isAvailable && place.ticket?.id && !saleEnded(ticket)
    })

    if (price) {
      if (price === DONATION_OPTION_ID) {
        places = places.filter(place => isDonationTicketDefinition(place.ticket))
      } else {
        places = places.filter(place => place.ticketPrice === price)
      }
    }

    if (zone) {
      places = places.filter(place => `${place.elementType}_${place.elementLabel}` === zone)
    }

    return places
  },
)

export const getSelectedTicketsWithPlaceInfo = createSelector(getPlaces, places => {
  return places.filter(place => place.inBasket).sort((a, b) => b.timeAddedToBasket - a.timeAddedToBasket)
})

export const getSelectedSeatingTickets = (state: State): SelectedTickets => {
  const placesInBasket = getSelectedTicketsWithPlaceInfo(state)

  return placesInBasket.reduce((acc, item) => {
    const quantity = acc[item.ticket.id]?.quantity || 0

    return {
      ...acc,
      [item.ticket.id]: {
        quantity: quantity + item.quantity,
      },
    }
  }, {})
}

export const getTotalSelectedTicketsQuantity = (state: State) =>
  getSelectedTicketsWithPlaceInfo(state).reduce((sum, {quantity}) => sum + quantity, 0)

export const getSelectedTicketsToPlaces = (state: State): TicketsToPlaces => {
  const placesInBasket = getSelectedTicketsWithPlaceInfo(state)
  const selectedTickets = getSelectedSeatingTickets(state)

  return Object.keys(selectedTickets).reduce((acc, ticket) => {
    const selectedPlaces = placesInBasket.filter(place => place.ticket?.id === ticket)

    const seatsInfo: SeatsInfo = selectedPlaces.reduce(
      (info, place) => {
        const quantity = place.reservationOptions?.reserveWholeElement ? place.places.length : place.quantity

        const placeIds = place.reservationOptions?.reserveWholeElement
          ? [...info.placeIds, ...place.places.map(p => p.id)]
          : [...info.placeIds, ...new Array(place.quantity).fill(place.id)]

        const donations = isDonationTicketDefinition(place.ticket)
          ? [...(info.donations ?? []), ...new Array(quantity).fill(place.donation || '0')]
          : undefined

        return {
          placeIds,
          donations,
        }
      },
      {placeIds: [], donations: undefined},
    )

    return {
      ...acc,
      [ticket]: seatsInfo,
    }
  }, {})
}

export const getSeatsReservationQuantities = (state): wix.events.ticketing.TicketReservationQuantity[] => {
  const ticketsToPlaces = getSelectedTicketsToPlaces(state)
  const selectedTicketDefinitionIds = Object.keys(ticketsToPlaces).sort(sortTicketDefinitionIds(state))

  return selectedTicketDefinitionIds.map(ticketDefinitionId => {
    const {placeIds, donations} = ticketsToPlaces[ticketDefinitionId]
    const ticketDetails: wix.events.ticketing.TicketDetails[] =
      placeIds.reduce((acc, seatId, placeIndex) => {
        const index = acc.findIndex(seat => seat.seatId === seatId)

        if (index !== -1) {
          const currentCapacity = acc[index]?.capacity ?? 1
          acc[index].capacity = currentCapacity + 1
        } else {
          acc.push({seatId, priceOverride: donations?.[placeIndex]})
        }

        return acc
      }, [] as wix.events.ticketing.TicketDetails[]) ?? undefined

    return {
      ticketDefinitionId,
      quantity: placeIds.length,
      ticketDetails,
    }
  })
}

export const getSelectedPlace = createSelector(getPlaces, places => places.find(place => place.selected))

export const getPlaceQuantity = createSelector(
  (places: PlaceWithTicketInfo[], id: string): number => getPlaceInfo(places, id)?.quantity ?? 0,
  places => places,
)

export const getPlaceInfo = (places: PlaceWithTicketInfo[], id: string): PlaceWithTicketInfo | undefined =>
  places.find(place => place.id === id)

export const isTicketLimitReached = (state: State) =>
  getEventTicketLimitPerOrder(state.event) <= getSelectedTicketsQuantity(state)

export const getPlaceTicketPriceText = (ticket: wix.events.ticketing.Ticket, t: TFunction, priceOverride?: string) => {
  if (priceOverride) {
    return getTicketPriceText({amount: priceOverride, currency: getTicketDefinitionCurrency(ticket)}, t)
  } else if (isDonationTicketDefinition(ticket)) {
    return t('tickets.donation')
  }
  return getTicketPriceText(ticket.price, t)
}

export const getLegendItems = (state: State, t: TFunction) =>
  state.seating.plan.categories
    .filter(category => Boolean(category.places.length))
    .map(({externalId, config: {color}}) => {
      const ticketDefinition = getTicketDefinitionByExternalId(state.tickets, externalId)

      if (!ticketDefinition) {
        return null
      }

      const isDonation = isDonationTicketDefinition(ticketDefinition)

      return {
        color,
        price: getPlaceTicketPriceText(ticketDefinition, t),
        amount: isDonation ? 0 : Number(ticketDefinition.price.amount),
      }
    })
    .filter(Boolean)
    .sort((a, b) => a.amount - b.amount)

export const calculatePlacesStock = (places: PlaceWithTicketInfo[]) =>
  places.reduce((acc, place) => {
    const ticket = place.ticket

    if (ticket?.id && !saleEnded(ticket)) {
      if (place.reservationOptions?.reserveWholeElement && place.places.some(item => !item.capacity)) {
        acc[place.id] = 0
      } else {
        const stock = place.capacity - place.quantity
        acc[place.id] = stock < 0 ? 0 : stock
      }
    } else {
      acc[place.id] = 0
    }

    return acc
  }, {} as Record<string, number>)

export const isShowAccessibilityMode = (state: State) => state.seating.showAccessibilityMode

export const getDefaultOption = (t: TFunction) => ({id: 'all', value: t('seatings_filters_all'), isSelectable: true})

export const getPriceOptions = (
  tickets: wix.events.ticketing.TicketDefinition[],
  t: TFunction,
): DropdownOptionProps[] => [
  getDefaultOption(t),
  ...tickets
    .map(ticket => {
      const isDonation = isDonationTicketDefinition(ticket)
      return {
        id: isDonation ? DONATION_OPTION_ID : getPlaceTicketPriceText(ticket, t),
        value: getPlaceTicketPriceText(ticket, t),
        sortValue: isDonation ? '0' : ticket.price.value,
        isSelectable: true,
      }
    })
    .filter(
      ({value}, index, allPrices) => allPrices.findIndex(({value: searchValue}) => value === searchValue) === index,
    )
    .sort((a, b) => Number(a.sortValue) - Number(b.sortValue)),
]

export const getZoneOptions = (plan: SeatingPlan, t: TFunction): DropdownOptionProps[] => {
  const zoneTypes = [
    {types: [Type.ROW], zoneTypeTranslation: 'seatings_filters_rows', itemTranslation: 'seatings_filters_row'},
    {
      types: [Type.TABLE, Type.ROUND_TABLE],
      zoneTypeTranslation: 'seatings_filters_tables',
      itemTranslation: 'seatings_filters_table',
    },
    {types: [Type.AREA], zoneTypeTranslation: 'seatings_filters_areas', itemTranslation: 'seatings_filters_area'},
  ]

  const allElements = plan.sections.flatMap(sector => sector.elements)

  return [
    getDefaultOption(t),
    ...zoneTypes.flatMap(({types, zoneTypeTranslation, itemTranslation}) => {
      const typeElements: Element[] = allElements.reduce((acc, curr) => {
        if (types.includes(curr.type) && !acc.find(item => item.title === curr.title)) {
          acc.push(curr)
        }

        return acc
      }, [])

      const typeElementsCount = typeElements.length

      if (!typeElementsCount) {
        return []
      }

      return [
        {
          id: types.join(','),
          value: t(zoneTypeTranslation, {count: typeElementsCount}),
          isSectionTitle: true,
          isSelectable: false,
        },
        ...typeElements
          .map(element => ({
            id: `${element.type}_${element.title}`,
            value: t(itemTranslation, {title: element.title, interpolation: {escapeValue: false}}),
            sortValue: element.title,
            isSelectable: true,
            isSectionTitle: false,
          }))
          .sort((a, b) => Number(a.sortValue) - Number(b.sortValue)),
      ]
    }),
  ]
}

export const DEFAULT_OPTION_ID = 'all'
export const DONATION_OPTION_ID = 'Donation'

export const getSelectedPriceOptionId = (state: State, priceOptions: DropdownOptionProps[]) => {
  const selectedPrice = state.seating.selectedPrice
  return (selectedPrice && priceOptions.find(price => price.id === selectedPrice)?.id) ?? DEFAULT_OPTION_ID
}

export const getSelectedZoneOptionId = (state: State, zoneOptions: DropdownOptionProps[]) => {
  const selectedZone = state.seating.selectedZone
  return (selectedZone && zoneOptions.find(element => element.id === selectedZone)?.id) ?? DEFAULT_OPTION_ID
}

export const getSelectedPriceValue = (priceOptions: DropdownOptionProps[], selectedPriceOptionId: string) =>
  priceOptions.find(({id}) => id === selectedPriceOptionId).value

export const getSelectedZoneValue = (zoneOptions: DropdownOptionProps[], selectedZoneOptionId: string) =>
  zoneOptions.find(({id}) => id === selectedZoneOptionId).value

export const getPlacesCount = (state: State) =>
  getFilteredPlaces(state).reduce(
    (acc, place) => (place.reservationOptions?.reserveWholeElement ? acc + place.places.length : acc + place.capacity),
    0,
  )

export const getElementLabelTitle = (elementType: Type, t: TFunction) => {
  const elementLabelHeaderMap = {
    [Type.AREA]: t('seatings_area'),
    [Type.ROW]: t('seatings_row'),
    [Type.TABLE]: t('seatings_table'),
    [Type.ROUND_TABLE]: t('seatings_table'),
  }

  return elementLabelHeaderMap[elementType]
}

export const getPlaceInfoItems = (place: PlaceWithTicketInfo, t: TFunction) => {
  const {sector, elementType, elementLabel, ticket, ticketPrice, fees, feesInfo, label, reservationOptions, places} =
    place
  const wholeElementReservation = Boolean(reservationOptions?.reserveWholeElement)
  const result = {
    placeInfo: [
      {label: t('seatings_sector'), value: sector},
      {label: getElementLabelTitle(elementType, t), value: elementLabel},
    ],
    ticketInfo: {
      ticketName: ticket?.name,
      ticketPrice,
      fees,
      feesInfo,
    },
  }

  if (wholeElementReservation) {
    result.placeInfo.push({label: t('seating_seatQuantity'), value: String(places.length)})
  } else if (!isPlaceArea(place)) {
    result.placeInfo.push({label: t('seatings_seat'), value: label})
  }

  return result
}

export const isPlaceArea = (place: Place) => place.elementType === Type.AREA

export const getPlaceInfoText = ({sector, elementLabel, elementType, label}: PlaceWithTicketInfo, t: TFunction) => {
  const section = sector ? `${t('seatings_sector')} ${sector}` : null
  const elLabel = elementLabel ? `${getElementLabelTitle(elementType, t)} ${elementLabel}` : null
  const placeLabel = label ? `${t('seatings_seat')} ${label}` : null
  return `${[section, elLabel, placeLabel].filter(Boolean).join(' ')}`
}

export const getPlaceDonationError = (state: State, placeId: string) => state.seating.donationErrors[placeId]

export const getOrderRemainder = (state: State, place: PlaceWithTicketInfo) => {
  const selectedTicketQuantity = getSelectedTicketQuantity(state, place.ticket?.id, place.id)
  const selectedTicketsQuantity = getSelectedTicketsQuantity(state)
  const eventTicketLimitPerOrder = getEventTicketLimitPerOrder(state.event)

  const totalSelectedTicketsQuantity = selectedTicketQuantity
    ? selectedTicketsQuantity - selectedTicketQuantity
    : selectedTicketsQuantity
  const orderRemainder = eventTicketLimitPerOrder - totalSelectedTicketsQuantity
  return orderRemainder
}

export const getSeatsPreliminaryInvoiceItems = (state: State): InvoiceSelectedItems => {
  return Object.entries(getSelectedTicketsToPlaces(state)).reduce((acc, [ticketId, {placeIds, donations}]) => {
    acc[ticketId] = {
      quantity: placeIds.length,
      priceOverrides: donations,
    }
    return acc
  }, {})
}
