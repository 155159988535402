import {IWixAPI} from '@wix/yoshi-flow-editor'
import {isTicketed, isRsvp, isExternal, isDraft, isWithoutRegistration} from '@wix/wix-events-commons-statics'
import {encodeQueryParams, UrlQueryParams} from '@wix/panda-js-utils'
import {VisitorType} from '@wix/events-types'
import {Store} from 'redux'
import {DETAILS_ROUTE} from '../../../../commons/constants/navigation'
import {ReservationState} from '../../../../commons/enums'
import {isEditor, isMobile, isPreview, isSite} from '../../../../commons/selectors/environment'
import {isTemplate} from '../../../../commons/selectors/instance'
import {isDetailsPageEnabled} from '../../../../commons/selectors/site-settings'
import {isMemberRsvpExists} from '../selectors/member-rsvp'
import {getUrl, getSlug} from '../selectors/navigation'
import {getReservationId} from '../selectors/reservation'
import {shouldNavigateToForm} from '../selectors/rsvp'
import {renderSEOTags} from '../services/seo'
import {GetState, RouteParams, State, StoreExtraArgs, ThankYouMessageState} from '../types'
import {parseLocation} from '../utils/navigation'
import {isRestrictedTo} from '../selectors/event'
import {isMember} from '../selectors/current-member-details'
import {updateSiteSettings, getSiteSettings} from '../../../../commons/actions/site-settings'
import {changeEvent} from './event'
import {getOrder} from './placed-order'
import {getPolicies} from './policies'
import {resetCouponCode} from './coupon'
import {clearInvoice} from './invoice'
import {clearCheckout} from './checkout'

export const NAVIGATE = 'NAVIGATE'
export const NAVIGATE_BACK = 'NAVIGATE_BACK'

// When used it causes full page reload BUT
// editor remembers where it was navigated to
// This is important when modals cause reloads, etc.
export const navigateViaEditor =
  (path: string) =>
  (dispatch: Function, getState: GetState, {wixCodeApi}: StoreExtraArgs) => {
    wixCodeApi.location.navigateToSection({
      sectionId: 'events',
      state: `${getState().event.slug}/${path}`,
      queryParams: '',
    })
  }

export const navigate =
  (route: DETAILS_ROUTE, routeParams: RouteParams = {}, query?: UrlQueryParams) =>
  async (dispatch: Function, getState: GetState, {wixCodeApi, flowAPI}: StoreExtraArgs) => {
    const state = getState()
    const {route: validRoute, routeParams: validRouteParams} = validateRoute(state, route, routeParams)

    if (isEditor(state)) {
      const {settings} = await dispatch(getSiteSettings())
      dispatch(updateSiteSettings({...settings.settings, detailsPagePath: route}))
      const {setCurrentRoute} = await import('../../../../commons/utils/current-path-storage')
      await setCurrentRoute(validRoute)
    }

    if (isSite(state) || isPreview(state)) {
      const queryParams = query || wixCodeApi.location.query
      try {
        await dispatch(handleRoute(validRoute, queryParams))
      } catch (e) {
        flowAPI.reportError(e)
      }

      const encodedQuery = encodeQueryParams(queryParams)
      const nextUrl = getUrl(getState(), validRoute, validRouteParams, encodedQuery)

      nextUrl && wixCodeApi.location.to(nextUrl)
    }
    dispatch({type: NAVIGATE, payload: {route: validRoute, ...validRouteParams}})
  }

const handleRoute =
  (route: DETAILS_ROUTE, query: UrlQueryParams) =>
  async (dispatch: Function, getState: GetState, {wixCodeApi}: StoreExtraArgs) => {
    switch (route) {
      case DETAILS_ROUTE.DETAILS:
        return handleDetailsRoute(getState(), dispatch, wixCodeApi)
      case DETAILS_ROUTE.FORM:
      case DETAILS_ROUTE.TICKET_FORM:
        return dispatch(getPolicies({showAlert: false}))
      case DETAILS_ROUTE.ORDER:
        const {eventId, orderNumber, token} = query
        return dispatch(getOrder(eventId, orderNumber, token))
      default:
        return null
    }
  }

const handleDetailsRoute = (state: State, dispatch: Function, wixCodeApi: IWixAPI) => {
  if (isTicketed(state.event)) {
    dispatch(resetCouponCode())
    dispatch(clearInvoice())
    dispatch(clearCheckout())
  }

  return renderSEOTags(wixCodeApi, state)
}

export const validateRoute = (state: State, route: DETAILS_ROUTE, routeParams: RouteParams) => {
  if (!state.event) {
    return {route: DETAILS_ROUTE.NOT_FOUND}
  }

  if (isExternal(state.event)) {
    return {route: DETAILS_ROUTE.DETAILS, routeParams}
  }

  switch (route) {
    case DETAILS_ROUTE.DETAILS:
      return {route: validateDetailsRoute(state), routeParams}
    case DETAILS_ROUTE.FORM:
      return {route: validateFormRoute(state), routeParams}
    case DETAILS_ROUTE.TICKET_FORM:
      return {route: validateTicketFormRoute(state), routeParams}
    case DETAILS_ROUTE.CHANGE_RSVP:
      return {route: validateChangeRsvpRoute(state), routeParams}
    case DETAILS_ROUTE.PICK_TICKETS:
      return {route: validateTicketsRoute(state), routeParams}
    case DETAILS_ROUTE.CHECKOUT:
      return {route: validateCheckoutRoute(state), routeParams}
    default:
      return {route, routeParams}
  }
}

const validateDetailsRoute = (state: State) => {
  const detailsPageEnabled = isDetailsPageEnabled(state.siteSettings)

  if (isWithoutRegistration(state.event) && !detailsPageEnabled) {
    return DETAILS_ROUTE.DETAILS
  }

  return isEditor(state) || detailsPageEnabled ? DETAILS_ROUTE.DETAILS : DETAILS_ROUTE.FORM
}

const validateTicketFormRoute = (state: State) => {
  if (!isDraft(state.event) && !isTemplate(state) && isSite(state) && !getReservationId(state)) {
    return isMobile(state) ? DETAILS_ROUTE.PICK_TICKETS : DETAILS_ROUTE.DETAILS
  }

  return DETAILS_ROUTE.TICKET_FORM
}

const validateCheckoutRoute = (state: State) => validateTicketFormRoute(state)

const validateFormRoute = (state: State) => {
  const isTicketedEvent = isTicketed(state.event)

  if (isWithoutRegistration(state.event)) {
    return DETAILS_ROUTE.DETAILS
  }

  if (isSite(state) && isRestrictedTo(state, VisitorType.MEMBER) && !isMember(state)) {
    return DETAILS_ROUTE.DETAILS
  }

  if (isTicketedEvent) {
    return isEditor(state) ? DETAILS_ROUTE.TICKET_FORM : DETAILS_ROUTE.DETAILS
  }

  if (isMemberRsvpExists(state) && !isTicketedEvent && !shouldNavigateToForm(state)) {
    return DETAILS_ROUTE.CHANGE_RSVP
  }

  return DETAILS_ROUTE.FORM
}

const validateChangeRsvpRoute = (state: State) =>
  isRsvp(state.event) && isMember(state) ? DETAILS_ROUTE.CHANGE_RSVP : DETAILS_ROUTE.DETAILS

const validateTicketsRoute = (state: State) => {
  if (isEditor(state) && !isMobile(state)) {
    // Probably opened desktop editor after playing in mobile preview
    return DETAILS_ROUTE.DETAILS
  }

  return DETAILS_ROUTE.PICK_TICKETS
}

export const navigateToDetails = () => navigate(DETAILS_ROUTE.DETAILS)

export const navigateBack = () => ({type: NAVIGATE_BACK})

export const navigateToForm = () => navigate(DETAILS_ROUTE.FORM)

export const navigateToTicketForm = (reservationId?: string) =>
  navigate(DETAILS_ROUTE.TICKET_FORM, undefined, {reservationId})

export const navigateToMobileCheckout = () => navigate(DETAILS_ROUTE.CHECKOUT)

export const navigateToTicketsPicker = () => navigate(DETAILS_ROUTE.PICK_TICKETS)

export const navigateToChangeRsvp = () => navigate(DETAILS_ROUTE.CHANGE_RSVP)

export const navigateToNotFound = () => navigate(DETAILS_ROUTE.NOT_FOUND)

export const navigateToLinkExpired = () => navigate(DETAILS_ROUTE.LINK_EXPIRED)

export const navigateToThankYouMessage = (thankYouMessageState: ThankYouMessageState) =>
  navigate(DETAILS_ROUTE.THANK_YOU_MESSAGES, {thankYouMessageState})

export const navigateToOrder = (
  reservationId: string,
  reservationState: ReservationState,
  queryParams: UrlQueryParams,
) => navigate(DETAILS_ROUTE.ORDER, {reservationState, reservationId}, queryParams)

export const navigateToTemplateOrder = () => navigate(DETAILS_ROUTE.TEMPLATE_ORDER)

export const navigateToMainPage =
  () =>
  async (dispatch: Function, getState: GetState, {wixCodeApi}: StoreExtraArgs) => {
    const {pages} = await wixCodeApi.site.getSiteStructure()
    const homePage = pages.find(page => page.isHomePage) ?? pages[0]
    wixCodeApi.location.to(homePage.url)
  }

export const handleInitialNavigation =
  () =>
  (dispatch: Function, getState: GetState, {wixCodeApi}: StoreExtraArgs) => {
    const {route, ...routeParams} = parseLocation(wixCodeApi)
    return dispatch(navigate(route, routeParams) as any)
  }

export const addLocationListener = (wixCodeApi: IWixAPI, store: Store) => {
  wixCodeApi.location.onChange(async () => {
    const state = store.getState()
    const navigationRoute = state.navigation.route
    const {route, slug} = parseLocation(wixCodeApi)
    const pickTicketsRouteInUrl = route === DETAILS_ROUTE.PICK_TICKETS
    const formRouteInUrl = route === DETAILS_ROUTE.FORM
    const ticketFormRouteInUrl = route === DETAILS_ROUTE.TICKET_FORM
    const detailsRouteInUrl = !route || route === DETAILS_ROUTE.DETAILS
    const pickTicketsRouteInState = navigationRoute === DETAILS_ROUTE.PICK_TICKETS
    const detailsRouteInState = navigationRoute === DETAILS_ROUTE.DETAILS
    const formRouteInState = navigationRoute === DETAILS_ROUTE.FORM
    const ticketFormRouteInState = navigationRoute === DETAILS_ROUTE.TICKET_FORM

    if (isEditor(state)) {
      const {setCurrentRoute} = await import('../../../../commons/utils/current-path-storage')
      store.dispatch(updateSiteSettings({detailsPagePath: route}) as any)
      return setCurrentRoute(parseLocation(wixCodeApi).route)
    }

    if (slug && slug !== getSlug(state)) {
      return store.dispatch<any>(changeEvent(slug))
    }

    if (isSite(state)) {
      if (formRouteInUrl && !formRouteInState) {
        return store.dispatch<any>(navigateToForm())
      }

      if (pickTicketsRouteInUrl && !pickTicketsRouteInState) {
        return store.dispatch<any>(navigateToTicketsPicker())
      }

      if ((detailsRouteInUrl && !detailsRouteInState) || (ticketFormRouteInUrl && !ticketFormRouteInState)) {
        return store.dispatch<any>(navigateToDetails())
      }
    }
  })
}
