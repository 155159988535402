import {getCouponDiscountCode} from '@wix/wix-events-commons-statics'
import {PlanInfo} from '@wix/events-types'
import {HttpError} from '@wix/http-client'
import {RegistrationErrorKey, ReservationState} from '../../../../commons/enums'
import {getFullLocale} from '../../../../commons/selectors/environment'
import {RegFormData, GetState, StoreExtraArgs} from '../types'
import {callAPI, createActions} from '../../../../commons/actions/data-action-helper'
import {onlyFreeTicketsSelected, getInvoice, isOrderCompleted} from '../selectors/placed-order'
import {getCurrentMemberDetails} from '../selectors/current-member-details'
import {getSelectedPaidPlan} from '../selectors/paid-plans'
import {extractFormData} from '../utils/api-data-mapper'
import {getAgreementToken, hasAgreedWithPolicies} from '../selectors/policies'
import {isValidPaymentAdded} from '../selectors/checkout'
import {FormStep} from '../constants/constants'
import {isRequestPending} from '../selectors/pending-requests'
import {Interaction} from '../utils/interactions'
import {placeOrderButtonClicked} from './payment'
import {navigateToOrder, navigateToNotFound, navigateToLinkExpired} from './navigation'
import {getPolicies} from './policies'
import {editStep} from './checkout'

export const PLACE_ORDER = createActions('PLACE_ORDER')
export const UPDATE_ORDER = createActions('UPDATE_ORDER')
export const GET_ORDER = createActions('GET_ORDER')
export const CANCEL_ORDER = 'CANCEL_ORDER'

export const cancelOrder = () => (dispatch: Function, getState: GetState) => {
  if (isOrderCompleted(getState().placedOrder.order)) {
    return dispatch({type: CANCEL_ORDER})
  } else {
    return Promise.resolve()
  }
}

export const placeOrder =
  (eventId: string, buyer: RegFormData, guests?: RegFormData[]) =>
  (dispatch: Function, getState: GetState, {flowAPI}: StoreExtraArgs) => {
    flowAPI.fedops.interactionStarted(Interaction.Checkout)
    const state = getState()

    if (isRequestPending(state, PLACE_ORDER.REQUEST)) {
      return
    }

    const {membersAreaEnabled} = state
    const couponCode = getCouponDiscountCode(getInvoice(state))
    const memberData = getCurrentMemberDetails(state)
    const memberId = membersAreaEnabled && memberData ? memberData.id : undefined
    const selectedPlan = getSelectedPaidPlan(state) || ({} as PlanInfo)
    const {benefitId, planOrderId} = selectedPlan
    const policyAgreementToken = getAgreementToken(state)
    const agreedWithPolicies = hasAgreedWithPolicies(state)

    const orderGuests = guests ? guests.map(extractFormData) : null

    return dispatch(
      callAPI(PLACE_ORDER, {
        eventId,
        buyer,
        guests: orderGuests,
        couponCode,
        memberId,
        planOrderId,
        benefitId,
        policyAgreementToken: agreedWithPolicies ? policyAgreementToken : undefined,
        locale: getFullLocale(state),
      }),
    )
      .then((response: any) => {
        const order = response.order

        if (onlyFreeTicketsSelected(order)) {
          dispatch(placeOrderButtonClicked())
        }

        dispatch(postPlaceOrder(order))

        return response
      })
      .catch((e: HttpError) => {
        if (e.response?.data?.metadata?.error_key === RegistrationErrorKey.INVALID_POLICY_AGREEMENT_TOKEN) {
          dispatch(handleInvalidPolicy())
        } else {
          throw e
        }
      })
  }

const handleInvalidPolicy = () => async (dispatch: Function, getState: GetState) => {
  const state = getState()
  await dispatch(getPolicies({showAlert: true}))
  dispatch(editStep(FormStep.Policies, isValidPaymentAdded(state)))
}

const postPlaceOrder =
  (order: wix.events.ticketing.Order) =>
  (dispatch: Function, getState: GetState, {flowAPI}: StoreExtraArgs) => {
    flowAPI.fedops.interactionEnded(Interaction.Checkout)
    const totalPrice = Number(order.totalPrice.amount)

    if (!totalPrice) {
      dispatch(
        navigateToOrder(order.reservationId, ReservationState.SUCCESS, {
          orderNumber: order.orderNumber,
          eventId: order.eventId,
        }),
      )
    }
  }

export const updateOrder =
  (eventId: string, orderNumber: string, buyer: RegFormData, guests?: RegFormData[]) =>
  (dispatch: Function, getState: GetState) =>
    dispatch(
      callAPI(
        UPDATE_ORDER,
        eventId,
        orderNumber,
        extractFormData(buyer),
        guests?.map(extractFormData),
        getFullLocale(getState()),
      ),
    )

export const getOrder =
  (eventId: string, orderNumber: string, token: string = '') =>
  (dispatch: Function, getState: GetState) => {
    return dispatch(callAPI(GET_ORDER, eventId, orderNumber, token, getFullLocale(getState()))).catch(
      (error: HttpError) => {
        setTimeout(() => {
          if (error.response?.status === 404) {
            dispatch(navigateToNotFound())
          } else {
            dispatch(navigateToLinkExpired())
          }
        })
      },
    )
  }
