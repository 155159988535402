import {getEventId} from '@wix/wix-events-commons-statics'
import {openDialogModal} from '../../../../commons/services/modal'
import {isDemoEvent} from '../selectors/event'
import {GetState} from '../types'
import {createActions, callAPI} from '../../../../commons/actions/data-action-helper'

export const GET_POLICIES = createActions('GET_POLICIES')
export const TOGGLE_AGREE_POLICIES = 'TOGGLE_AGREE_POLICIES'
export const RESET_POLICIES = 'RESET_POLICIES'

export const resetPolicies = () => ({
  type: RESET_POLICIES,
})

export const toggleAgreePolicies = () => ({
  type: TOGGLE_AGREE_POLICIES,
})

export const getPolicies =
  ({showAlert}: {showAlert: boolean}) =>
  (dispatch: Function, getState: GetState) => {
    const state = getState()
    if (!isDemoEvent(state)) {
      return dispatch(callAPI(GET_POLICIES, getEventId(state.event), {showAlert}))
    }
  }

interface OpenPolicyModalArgs {
  policyId: string
  returnFocusElement: string
}
export const openPolicyModal =
  ({policyId, returnFocusElement}: OpenPolicyModalArgs) =>
  (dispatch: Function, getState: GetState) => {
    const state = getState()
    dispatch(
      openDialogModal({
        type: 'policy',
        params: {
          policyId,
          eventId: getEventId(state.event),
        },
        returnFocusElement,
      }),
    )
  }
